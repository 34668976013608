






























































import { PostUpdatePassword } from "@/api/user";
import { GetResponseErrors } from "@/common/utils";
import TheSnackbar from "@/components/TheSnackbar.vue";
import Vue from "vue";
export default Vue.extend({
  metaInfo: {
    title: 'Cambiar contraseña',
  },
  components: { TheSnackbar },
  data() {
    return {
      isPasswordType: {
        actual: true,
        new: true,
        repeatNew: true,
      },
      form: {
        old_password: "",
        new_password: "",
      },
      repeatNewPassword: "",
      isSubmitting: false,
    };
  },
  methods: {
    validate() {
      let isValid = true;
      if (this.form.new_password != this.repeatNewPassword) {
        isValid = false;
        (this.$refs.snackbar as any).show("Las contraseñas no coinciden");
      }
      return isValid;
    },
    async handleSubmit() {
      if (!this.validate()) {
        return;
      }
      this.isSubmitting = true;
      try {
        const res = await PostUpdatePassword(this.form);
          (this.$refs.snackbar as any).show("Contraseña actualizada exitosamente");
      } catch (err) {
        (this.$refs.snackbar as any).show(GetResponseErrors(err));
      }
      this.isSubmitting = false;
    },
  },
});
